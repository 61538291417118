'use strict';

// Mettere commenti
var currentLanguage = $('html').attr('lang');
var siteUrl = $('body').data('site-url');
var templateUrl = $('body').data('template-url');

$(document).ready(function() {

	siteInit.slider();
	siteInit.carouselMobile();
	siteInit.carousel();
	siteInit.modal();
	siteInit.videoPreview();
	siteInit.toggleClass();

});

$(window).on('load', function() {
	// Load function
});

$(window).scroll(function() {
	// Scroll function
});

$(window).resize(function() {

	if (window.matchMedia('(min-width: 992px)').matches) {
		// The viewport is at least 992 pixels wide
	}

});

var siteInit = {
	// Slider
	slider: function() {

		var $el = $('.js-slider');

		if ($el.length == 0) {

			return;

		}

		$el.owlCarousel({
			loop: true,
			dots: true,
			margin: 0,
			animateOut: 'fadeOut',
			//animateIn: fade
			center: false,
			autoHeight: true,
			stagePadding: 0,
			responsiveClass: true,
			nav:true,
			autoplay: true,
			responsive: {
				0: {
					items: 1,
					dots: true
				},
				992: {
					items: 1
				}
			}
		});

	},

	// Carousel
	carouselMobile: function() {

		// TODO: Valutare il destroy
		if (window.matchMedia('(max-width: 1200px)').matches) {

			var $el = $('.js-carousel-mobile');

			if ($el.length == 0) {

				return;

			}

			$el.owlCarousel({
				loop: true,
				dots: true,
				margin: 24,
				center: false,
				stagePadding: 0,
				responsiveClass: true,
				responsive: {
					0: {
						autoplay: true,
						nav: false,
						items: 1,
						margin: 12,
						dots: true
					},
					768: {
						items: 2
					},
					992: {
						items: 3,
						margin: 24,
						nav: true
					}
				}
			});

		}

	},

	// Carousel
	carousel: function() {

		var $el = $('.js-carousel');

		if ($el.length == 0) {

			return;

		}

		$el.owlCarousel({
			loop: true,
			dots: true,
			margin: 24,
			center: false,
			stagePadding: 0,
			responsiveClass: true,
			responsive: {
				0: {
					autoplay: true,
					nav: false,
					items: 1,
					margin: 12,
					dots: true
				},
				768: {
					items: 2
				},
				992: {
					items: 4,
					margin: 24,
					nav: false
				}
			}
		});

	},

	// Modal
	modal: function() {

		var $el = $('[href$=".jpg"], [href$=".png"], [href$=".gif"], [href$=".jpeg"], [href$=".webp"]');

		if ($el.length == 0) {

			return;

		}

		$el.fancybox();

	},

	// Video preview
	videoPreview: function() {

		var $el = $('.page__preview');

		if ($el.length == 0) {

			return;

		}

		$el.fancybox();

		var timeoutID;
		var timeoutHideID;

		timeoutID = window.setTimeout(showPreview, 10000);

		function showPreview() {

			$el.fadeIn();
			window.clearTimeout(timeoutID);
			timeoutHideID = window.setTimeout(hidePreview, 22000);

		}

		function hidePreview() {

			$el.fadeOut();
			window.clearTimeout(timeoutHideID);

		}

	},

	// Toggle class
	toggleClass: function() {

		var elName = '.js-toggle-class';
		var $el = $(elName);

		if ($el.length == 0) {

			return;

		}

		$('body').on('click', elName, function(event) {

			event.preventDefault();
			var $elCliked = $(this);
			var toggleClassName = 'is-active';

			// Add data-toggle-class-name="customclass" to change the default class name
			if ($elCliked.attr('data-toggle-class-name')) {

				toggleClassName = $elCliked.data('toggle-class-name');

			}

			// Add data-toggle-class-selector="selector1 selector2" to toggle class on different tag
			if ($elCliked.attr('data-toggle-class-selector')) {

				var toggleClassSelector = $elCliked.data('toggle-class-selector');
				var obj = toggleClassSelector.split(' ');

				$.each(obj, function(index, value) {

					$('.' + value).toggleClass(toggleClassName);

				});

			} else {

				$elCliked.toggleClass(toggleClassName);

			}

		});

	}
};
